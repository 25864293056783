import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import QueryString from "qs";

const prepareQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_CIMA_ENDPOINT,
  mode: "cors",
  timeout: process.env.REACT_APP_CIMA_TIMEOUT,
  //credentials: "include",
  prepareHeaders: (Headers, { getState }) => {
    const token = getState().cimaxxxx.token;
    Headers.set("Accept", "application/json");
    Headers.set("Content-Type", "application/json");
    Headers.set("Modulecima", "usuarios");
    if (token) {
      Headers.set("Authorization", `Bearer ${token}`);
    }
    return Headers;
  },
  paramsSerializer: (params) => {
    return QueryString.stringify(params);
  },

})

const baseQuery = async (args, api, extraOptions) => {
  let getData = await prepareQuery(args, api, extraOptions);
  return getData;
}

export const cimaxxxxApiSlice = createApi({
  reducerPath: "cimaxxxxApiSlice",
  baseQuery,
  overrideExisting: true,
  endpoints: (builder) => ({
    loginCima: builder.mutation({
      query: (credentials) => ({
        url: "/loginCima",
        method: "POST",
        body: {
          USRIDXXX: credentials.USRIDXXX,
          USRPASXX: credentials.USRIDXXX,
          SISTEMAX: "CIMA",
          EXTERNAL: true,
        },
        //credentials: "include",
      }),
    }),
    getUsrController: builder.query({
      query: (filter) => ({
        url: `Paramgen/Controlx/getUsersByModule/${filter.CLIIDXXX}/${filter.APLALLXX}?${filter.filter}`,
        method: "GET",
        headers: {
          Modulecima: "Paramgen",
        },
      }),
    }),
  }),
});

export const { useLoginCimaMutation, useLazyGetUsrControllerQuery } = cimaxxxxApiSlice;
